import React from "react";
import "./PrivacyAndPolicy.css";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const PrivacyAndPolicyPage: React.FC = () => {
    const navigate = useNavigate();
    const logoImage = require('../../assets/images/logo.png');

    return (
        <div className="col-md-12 pollvote-background_color text-center">
            <div className="privacy-policy">
                <div className="organizer-logo text-center">
                    <img src={logoImage} alt="Logo" />
                    <h2 className='welcome-text'>Welcome to the fastest way to <br></br>  Poll or Elect!</h2>
                </div>
                <div>
                    <h1 className="privacy-policy-heading text-center">Privacy Policy</h1>
                </div>
                <div className="privacy-section">
                    <ol>
                        <li>
                            Privacy
                            <p>
                                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                                used in laying out print, graphic or web designs. The passage is
                                attributed to an unknown typesetter. The passage experienced a
                                surge in popularity during the 1960s when Letraset used it on
                                their dry-transfer sheets, and again during the 90s as desktop
                                publishers bundled the text with their software.
                            </p>
                        </li>
                        <li>Lorem ipsum, or lipsume
                            <p>
                                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                                used in laying out print, graphic or web designs. The passage is
                                attributed to an unknown typesetter. The passage experienced a
                                surge in popularity during the 1960s when Letraset used it on
                                their dry-transfer sheets, and again during the 90s as desktop
                                publishers bundled the text with their software.
                            </p>
                        </li>
                        <li>Lorem ipsum, or lipsume
                            <p>
                                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                                used in laying out print, graphic or web designs. The passage is
                                attributed to an unknown typesetter. The passage experienced a
                                surge in popularity during the 1960s when Letraset used it on
                                their dry-transfer sheets, and again during the 90s as desktop
                                publishers bundled the text with their software.
                            </p>
                        </li>
                        <li>Lorem ipsum, or lipsume
                            <p>
                                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                                used in laying out print, graphic or web designs. The passage is
                                attributed to an unknown typesetter. The passage experienced a
                                surge in popularity during the 1960s when Letraset used it on
                                their dry-transfer sheets, and again during the 90s as desktop
                                publishers bundled the text with their software.
                            </p>
                        </li>
                        <li>Lorem ipsum, or lipsume
                            <p>
                                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                                used in laying out print, graphic or web designs. The passage is
                                attributed to an unknown typesetter. The passage experienced a
                                surge in popularity during the 1960s when Letraset used it on
                                their dry-transfer sheets, and again during the 90s as desktop
                                publishers bundled the text with their software.
                            </p>
                        </li>
                        <li>Lorem ipsum, or lipsume
                            <p>
                                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                                used in laying out print, graphic or web designs. The passage is
                                attributed to an unknown typesetter. The passage experienced a
                                surge in popularity during the 1960s when Letraset used it on
                                their dry-transfer sheets, and again during the 90s as desktop
                                publishers bundled the text with their software.
                            </p>
                        </li>
                    </ol>

                </div>

                <div className="setup-buttons">
                    <Button
                        style={{
                            background: 'transparent linear-gradient(180deg, #19C7AD 0%, #15A18C 100%) 0% 0% no-repeat padding-box',
                            borderColor: '#19C7AD',
                            color: 'white',
                            width: '100%',
                            padding: '19px',
                            fontSize: '19px',
                            fontWeight: 300,
                            marginTop: '30px',
                            marginRight: '5px',
                            borderRadius: '17px',
                            border: 'none',
                            cursor: 'pointer',
                            height: '66px',
                            transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
                        }}
                        onClick={() => navigate("/register")}
                    >
                        Setup
                    </Button>
                    <Button className='login-btn'
                        style={{
                            backgroundColor: 'transparent',
                            border: '1px solid #19c7ad',
                            color: '#19c7ad',
                            width: '100%',
                            padding: '19px',
                            fontSize: '18px',
                            fontWeight: 300,
                            marginTop: '30px',
                            marginLeft: '5px',
                            borderRadius: '17px',
                            // border: 'none',
                            cursor: 'pointer',
                            height: '66px',
                            transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
                        }}
                        onClick={() => navigate("/login")}
                    >
                        Login
                    </Button>
                </div>

            </div>
        </div>
    )
};

export default PrivacyAndPolicyPage;
