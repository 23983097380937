import React from 'react';
import { Layout } from 'antd';
import {
    UserOutlined
} from '@ant-design/icons';
import Spinner from '../Spinner/Spinner';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { Footer } from 'antd/es/layout/layout';
import Sidenav from './Sidenav/Sidenav';

const { Header, Content, Sider } = Layout;

interface IAPPProps {
    children: React.ReactNode
}
const LayoutComponent: React.FC<IAPPProps> = ({ children }) => {
    const logoImage = require('../../../assets/images/logo.png');
    const { user } = useSelector((state: RootState) => state.auth);
    const { loading } = useSelector((state: RootState) => state.event);

    return (
        <Layout>
            <Header style={{ alignItems: 'center', backgroundColor: '#fff' }}>
                <img src={logoImage} alt="Logo" className="logo-image" />
                <div style={{ float: 'right' }}>
                    <UserOutlined /> <strong>{user?.username?.toUpperCase()}</strong>
                    {loading && <Spinner />}
                </div>
            </Header>
            <Content style={{ height: '82vh' }}>
                <div style={{ display: 'flex' }}>
                    <div>
                        <Sidenav statusId={user?.organiserType === 'ELECTION' ? 1 : 2} />
                    </div>
                    {children}
                </div>
            </Content>
            <Footer className='footer' style={{ textAlign: 'center', backgroundColor: '#fff' }}>
                Copyrights by Transdyno 2023
            </Footer>
        </Layout >
    );
};

export default LayoutComponent;
