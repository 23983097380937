import React from 'react';
import Banner from '../../components/Common/Banner/Banner';
import { Link } from 'react-router-dom';
import './ForgotPassword.css';
import { Button, Col, Form, Input, Row } from 'antd';
import LogoWithDescription from '../../components/Common/LogoWithDescription/LogoWithDescription';

const ForgotPasswordPage: React.FC = () => {
    const [form] = Form.useForm();

    return (
        <div>
            <Row className='row-with-gap'>
                <Banner />
                <Col md={11} className="pollvote-background_color ">
                    <div className="setup-profile">
                        <div className="setup-profile-inner">
                           
                                <LogoWithDescription description={``} />
                                <div className="welcome-text-2 mb-5">
                                <h5>Just let us know the email you use to sign in to Pollvote and we'll help you get your password back</h5>
                            </div>
                            <Form form={form} name='register' scrollToFirstError>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        { required: true, message: 'Email is Required!' },
                                    ]}
                                >
                                    <>
                                    <Input className="did-floating-input" placeholder='Email Address' />
                                    {/* <label className="did-floating-label">Email Address</label> */}
                                    </>
                                   
                                </Form.Item>

                                <Button
                                    className='mb-3'
                                    htmlType="submit"
                                    style={{
                                        background: 'transparent linear-gradient(180deg, #19C7AD 0%, #15A18C 100%) 0% 0% no-repeat padding-box',
                                        borderColor: '#19C7AD',
                                        color: 'white',
                                        width: '100%',
                                        padding: '19px',
                                        fontSize: '19px',
                                        fontWeight: 300,
                                        // marginTop: '10px',
                                        borderRadius: '17px',
                                        border: 'none',
                                        cursor: 'pointer',
                                        height: '66px',
                                        transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
                                    }}


                                >
                                    Send me the password
                                </Button>
                                <div className="mb-3 text-center">
                                    <Link to="/login" style={{ color: '#19C7AD', fontSize: '18px', }}>
                                        Take me back to login
                                    </Link>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ForgotPasswordPage;
