interface MenuItem {
    key: string;
    label: string;
    children?: MenuItem[];
    type?: 'group';
}

export const getMenuItems = (statusId: number): MenuItem[] => {
    const items1: MenuItem[] = [
        {
            key: '/create',
            label: 'Create Events',
            type: 'group',
        },
        {
            key: '/divider-create-events',
            label: ' ',
            type: 'group',
        },
        {
            key: '/election',
            label: 'Private Elections',
        },
        {
            key: '/general/election',
            label: 'Public Elections',
        },
        {
            key: '/referendum',
            label: 'Referendum',
        },
        {
            key: '/recall',
            label: 'Recall',
        },
        {
            key: '/list',
            label: 'Event List',
            type: 'group',
        },
        {
            key: '/divider-create-events',
            label: ' ',
            type: 'group',
        },
        {
            key: '/list',
            label: 'Current Events',
            children: [
                {
                    key: '/events/draft',
                    label: 'Draft Events',
                },
                {
                    key: '/events/ready',
                    label: 'Open Events',
                },
                {
                    key: '/events/live',
                    label: 'Live Events',
                },

            ],
        },
        {
            key: '/events/history',
            label: 'History of Events',
        },
        {
            key: '/divider-event-list',
            label: ' ',
            type: 'group',
        },
        {
            key: '/equipment',
            label: 'Equipment',
        },
        {
            key: '/research/report',
            label: 'Research / Report',
        },
        {
            key: '/plan',
            label: 'Plan',
        },
    ];

    const items2: MenuItem[] = [
        {
            key: '/create',
            label: 'Create Events',
            type: 'group',
        },
        {
            key: '/divider-create-events',
            label: ' ',
            type: 'group',
        },
        {
            key: '/poll',
            label: 'Polls',
        },
        {
            key: '/survey',
            label: 'Survey',
        },
        {
            key: '/list',
            label: 'Event List',
            type: 'group',
        },
        {
            key: '/divider-create-events',
            label: ' ',
            type: 'group',
        },
        {
            key: '/list',
            label: 'Current Events',
            children: [
                {
                    key: '/events/draft',
                    label: 'Draft Events',
                },
                {
                    key: '/events/ready',
                    label: 'Open Events',
                },
                {
                    key: '/events/live',
                    label: 'Live Events',
                },

            ],
        },
        {
            key: '/events/history',
            label: 'History of Events',
        },
        {
            key: '/divider-event-list',
            label: ' ',
            type: 'group',
        },
        {
            key: '/research/report',
            label: 'Research / Report',
        },
        {
            key: '/plan',
            label: 'Plan',
        },
    ];

    return statusId === 1 ? items1 : items2;
};
