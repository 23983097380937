import React, { useState } from 'react';
import Banner from '../../components/Common/Banner/Banner';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OtpForm from '../../components/Common/OtpForm/OtpForm';
import { ILoginProps } from '.';
import { authService } from '../../services/authService';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import LogoWithDescription from '../../components/Common/LogoWithDescription/LogoWithDescription';
import { useNotification } from '../../context/NotificationContext';
import './Login.css';


const LoginPage: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [isSendOtp, setIsSendOtp] = useState(false);
    const [verifyOtpLoading, setVerifyOtpLoading] = useState(false);
    const dispatch = useDispatch();
    const { openNotification } = useNotification();

    const handleSubmit = async (values: ILoginProps) => {
        try {
            setLoading(true);
            const res = await authService.login(values);
            if (res.isSuccess) {
                setIsSendOtp(true);
                openNotification('success', res.message, 'topRight');
            }
        } catch (error: any) {
            openNotification('error', error.message, 'topRight');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitOtp = async (otp: any) => {
        try {
            setVerifyOtpLoading(true);
            var res = await authService.verifyOtp(form.getFieldValue('email'), otp, dispatch);
            if (res.isSuccess) {
                openNotification('success', res.message, 'topRight');
            }
        } catch (error: any) {
            openNotification('error', error.message, 'topRight');
        } finally {
            setVerifyOtpLoading(false);
        }
    }

    const handleResendOtp = async () => {
        try {
            var res = await authService.sendOtp(form.getFieldValue('email'));
            if (res.isSuccess) {
                openNotification('success', res.message, 'topRight');
            }
        } catch (error: any) {
            openNotification('error', error.message, 'topRight');
        }
    }

    return (
        <div className='container-fluid pollvote'>
            <Row style={{ display: 'flex' }}>
                <Banner />
                <Col md={11} className="pollvote-background_color mt-5" >
                    <div className="setup-profile mt-5">
                        <div className="setup-profile-inner">

                            <LogoWithDescription description={isSendOtp ? '' : ''} />
                            <div className='welcome-text-2'>
                                <h4>Email verification code</h4>
                            </div>
                            <div>
                                {isSendOtp ? (
                                    <OtpForm buttonLabel='Verify' loading={verifyOtpLoading} onSubmit={handleSubmitOtp} onResend={handleResendOtp} />
                                ) : (
                                    <Form form={form} name='register' className='otpdigits' scrollToFirstError onFinish={handleSubmit}>
                                        <div className="did-floating-label-content">
                                            <Form.Item
                                                name="email"
                                                rules={[
                                                    { required: true, message: 'Email is Required!' },
                                                ]}
                                            >
                                                <>
                                                    <Input type='email' className="did-floating-input" name='email-input' placeholder='E-mail' />
                                                    {/* <label className="did-floating-label" >E-mail</label> */}

                                                </>

                                            </Form.Item>
                                            <Form.Item
                                                name="password"
                                                rules={[
                                                    { required: true, message: 'Password is required!' },
                                                    { min: 8, message: 'Password must be at least 8 characters long.' },
                                                ]}
                                            >
                                                <Input.Password
                                                    className="password-input"
                                                    placeholder="Password"
                                                    visibilityToggle
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="mb-3 foget-password">
                                            <Link to="/forgot-password" style={{ color: '#aba2a2c7' }}>
                                                Forgot Password?
                                            </Link>
                                        </div>

                                        <Form.Item
                                            name="rememberMe"
                                            valuePropName="checked"
                                        >
                                            <Checkbox className='remeber-checkbox'>
                                                Remember Me
                                            </Checkbox>
                                        </Form.Item>

                                        <Button
                                            className='mb-3'
                                            htmlType="submit"
                                            style={{
                                                background: 'transparent linear-gradient(180deg, #19C7AD 0%, #15A18C 100%) 0% 0% no-repeat padding-box',
                                                borderColor: '#19C7AD',
                                                color: 'white',
                                                width: '100%',
                                                padding: '19px',
                                                fontSize: '19px',
                                                fontWeight: 300,
                                                borderRadius: '17px',
                                                border: 'none',
                                                cursor: 'pointer',
                                                height: '66px',
                                                transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
                                            }}
                                            loading={loading}
                                        >
                                            Login
                                        </Button>
                                        <div className="mb-3  text-center">
                                            <Link to="/" style={{ color: '#19C7AD', fontSize: '18px', }}>
                                                or setup account
                                            </Link>
                                        </div>
                                    </Form>
                                )
                                }
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default LoginPage;
