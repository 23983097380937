import React, { Suspense, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./app/store";
import { LoadingOutlined } from "@ant-design/icons";
import Spinner from "./Common/Spinner/Spinner";
import Layout from "./Common/Layout/Layout";

const ProtectedRoute: React.FC = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  return isAuthenticated ? (
    <Layout>
      <Suspense
        fallback={
          <div style={{ alignItems: "center" }}>
            <Spinner />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </Layout>
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectedRoute;
