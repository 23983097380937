import { createSlice } from '@reduxjs/toolkit';

interface EventState {
    loading: boolean;
    events: any;
    eventDetails: any;
    eventQuestions: any;
    coverageAreas: any;
    electoralAreas: any;
    precincts: any;
    participationCriteriaRules: any;
    politicalParties: any;
    instruction: any
}

const initialState: EventState = {
    loading: false,
    events: [],
    eventDetails: [],
    eventQuestions: [],
    coverageAreas: [],
    electoralAreas: [],
    precincts: [],
    participationCriteriaRules: [],
    politicalParties: [],
    instruction: ""
};

const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        fetchEvents: (state, action) => {
            state.events = action.payload.events;
        },
        fetchEventDetails: (state, action) => {
            state.eventDetails = action.payload.eventDetails;
        },
        fetchCoverageAreas: (state, action) => {
            state.coverageAreas = action.payload.coverageAreas;
        },
        fetchPrecincts: (state, action) => {
            state.precincts = action.payload;
        },
        fetchEventQuestions: (state, action) => {
            state.eventQuestions = action.payload.eventQuestions;
        },
        fetchParticipationCriteriaRules: (state, action) => {
            state.participationCriteriaRules = action.payload.participationCriteriaRules;
        },
        fetchPoliticalParties: (state, action) => {
            state.politicalParties = action.payload;
        },
        fetchInstructions: (state, action) => {
            state.instruction = action.payload.instruction
        },
        showSpinner: (state, action) => {
            state.loading = action.payload.loading;
        },
        fetchParties: (state, action) => {
            state.loading = action.payload.loading;
        },
        setElectoralAreaOptions: (state, action) => {
            state.electoralAreas = action.payload;
        },
        setPrecinctOptions: (state, action) => {
            state.precincts = action.payload;
        },
    },
});

export const { showSpinner, fetchEvents, fetchEventDetails, fetchCoverageAreas, fetchEventQuestions, fetchParticipationCriteriaRules, fetchPoliticalParties, fetchInstructions, fetchParties, fetchPrecincts, setElectoralAreaOptions, setPrecinctOptions } = eventSlice.actions;

export default eventSlice.reducer;
