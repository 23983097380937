import axios, { AxiosInstance } from 'axios';
import config from '../config/config';

const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_ENV === 'development' ? config.development.apiUrl : config.production.apiUrl,
    timeout: 10000,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const userString = localStorage.getItem('user');
        if (userString) {
            const user = JSON.parse(userString);
            if (user && user.token) { 
                config.headers['Authorization'] = `Bearer ${user.token}`;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
