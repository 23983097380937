import React from 'react';
import './Home.css';
import Banner from '../../components/Common/Banner/Banner';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'antd';

const HomePage: React.FC = () => {
    const navigate = useNavigate();
    const logoImage = require('../../assets/images/logo.png');

    return (
        <div className='login-page'>
            <Row style={{ display: 'flex' }}>
                <Banner />
                <Col sm={11} className="pollvote-background_color text-center">
                    <div className="setup-profile">
                        <div className="setup-profile-inner">
                            <div className="organizer-logo">
                                <img src={logoImage} alt="Logo" />
                                <h2 className='welcome-text'>Welcome to the fastest way to <br></br>  Poll or Elect!</h2>
                            </div>
                            <div>
                                <h1 className="spend-less-text text-center">Spend Less Get More Votes</h1>
                            </div>
                            <div className="setup-buttons">
                                <Button
                                    style={{
                                        background: 'transparent linear-gradient(180deg, #19C7AD 0%, #15A18C 100%) 0% 0% no-repeat padding-box',
                                        borderColor: '#19C7AD',
                                        color: 'white',
                                        width: '100%',
                                        padding: '19px',
                                        fontSize: '19px',
                                        fontWeight: 300,
                                        marginTop: '30px',
                                        borderRadius: '17px',
                                        border: 'none',
                                        cursor: 'pointer',
                                        height: '66px',
                                        transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
                                    }}
                                    onClick={() => navigate("/register")}
                                >
                                    Setup
                                </Button>
                                <Button className='login-btn'
                                    style={{
                                        backgroundColor: 'transparent',
                                        border: '1px solid #19c7ad',
                                        color: '#19c7ad',
                                        width: '100%',
                                        padding: '19px',
                                        fontSize: '18px',
                                        fontWeight: 300,
                                        marginTop: '30px',
                                        borderRadius: '17px',
                                        // border: 'none',
                                        cursor: 'pointer',
                                        height: '66px',
                                        transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
                                    }}
                                    onClick={() => navigate("/login")}
                                >
                                    Login
                                </Button>
                            </div>
                        </div>
                    </div></Col>
            </Row>
        </div >
    );
};

export default HomePage;
