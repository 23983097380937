import React from 'react';
import { Menu } from 'antd';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { getMenuItems } from './menuItems';
import {
    LogoutOutlined
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setUnAuthenticated } from '../../../features/auth/authSlice';
import { useNotification } from '../../../../context/NotificationContext';
import './Sidenav.css';

const Sidenav: React.FC<{ statusId: number }> = ({ statusId }) => {
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate();
    const { openNotification } = useNotification();

    const selectedKey = location.pathname.includes('/edit') ? location.pathname.split('/edit')[0] : location.pathname;
    const menuItems = getMenuItems(statusId);

    return (
        <div>
            <Menu
                mode="inline"
                theme="light"
                selectedKeys={[selectedKey]}
                onClick={({ key }) => navigate(key)}
                style={{
                    minWidth: '15vw',
                    overflow: 'auto',
                    height: '87vh',
                    left: 0,
                    top: 0,
                    bottom: 0,
                }}
            >
                {menuItems.map((item: any) =>
                    item.type === 'group' ? (
                        item.key.includes('divider') ? (
                            <Menu.Divider key={item.key} style={{
                                borderTop: '1px solid #ccc',
                                margin: '10px 0',
                            }} />
                        ) : (
                            <Menu.Item key={item.key} disabled >
                                <span style={{ fontWeight: '600', fontSize: '16px', color: '#bfb8b8' }}>
                                    {item.label}
                                </span>
                            </Menu.Item>
                        )
                    ) : item.children ? (
                        <Menu.SubMenu key={item.key} title={item.label} icon={item.icon}>
                            {item.children.map((subItem: any) => (
                                <Menu.Item key={subItem.key}>{subItem.label}</Menu.Item>
                            ))}
                        </Menu.SubMenu>
                    ) : (
                        <Menu.Item key={item.key} icon={item.icon}>
                            {item.label}
                        </Menu.Item>
                    )
                )}
            </Menu>
            <div className='Logout'>
                <NavLink to='' id='logout-profile'
                    onClick={() => {
                        dispatch(setUnAuthenticated());
                        openNotification('success', 'Logout Successfully.', 'topRight');
                    }} >
                    <LogoutOutlined />Logout
                </NavLink>
            </div>
        </div>
    );
};

export default Sidenav;
