import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { Link } from 'react-router-dom';
import { OtpFormProps } from '.';
import { Button, Form, Input, message } from 'antd';
import './OtpForm.css';

const OtpForm: React.FC<OtpFormProps> = ({ onSubmit, onResend, buttonLabel, loading }) => {
    const [otp, setOtp] = useState('');
    const [remainingTime, setRemainingTime] = useState(119);
    const [form] = Form.useForm();

    useEffect(() => {
        const timer = setInterval(() => {
            setRemainingTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const formattedTime =
        remainingTime > 0
            ? `${Math.floor(remainingTime / 60)}:${remainingTime % 60 < 10 ? '0' : ''}${remainingTime % 60}`
            : '';

    const onFinish = () => {
        if (otp.length === 6) {
            onSubmit(otp);
        } else {
            message.error('Please enter a valid OTP.');
        }
    };

    return (
        <div>
            <Form form={form}
                noValidate name='otpForm' onFinish={onFinish}>
                <div className='verifyotp'>
                    <Form.Item
                        name="otp"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the OTP.',
                            },
                            {
                                len: 6,
                                message: 'OTP must be 6 characters long.',
                            },
                            {
                                pattern: /^\d+$/,
                                message: 'Please enter valid otp.',
                            },
                        ]}
                    >
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            renderSeparator={<span style={{ margin: '0 5px' }}></span>}
                            inputStyle={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '4px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                border: '1px solid #ccc',
                                textAlign: 'center',
                            }}
                            containerStyle={{ marginTop: '3rem' }}
                            renderInput={(props) => <input {...props} />}
                        />
                    </Form.Item>
                </div>
                <div className='code-detail'>
                    {remainingTime > 0 && <p style={{ marginTop: '2rem' }}>Code expires in {formattedTime}</p>}
                </div>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    className='mb-4'
                    style={{
                        background: 'transparent linear-gradient(180deg, #19C7AD 0%, #15A18C 100%) 0% 0% no-repeat padding-box',
                        borderColor: '#19C7AD',
                        color: 'white',
                        width: '100%',
                        padding: '19px',
                        fontSize: '19px',
                        fontWeight: 300,
                        marginTop: '30px',
                        borderRadius: '17px',
                        border: 'none',
                        cursor: 'pointer',
                        height: '66px',
                        transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
                    }}
                    disabled={!otp ? true : false}
                >
                    {buttonLabel}
                </Button>
            </Form>
            <div className="mb-1 text-code">
                <span>Didn't get the code?</span>
                <Link
                    to="/"
                    style={{
                        color: '#19C7AD',
                        marginLeft: '3px',
                        cursor: 'pointer',
                        fontSize: '17px',
                        fontFamily: 'Poppins',
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        form.setFieldValue('otp', '');
                        setRemainingTime(119);
                        onResend();
                    }}
                >
                    Resend Code
                </Link>
            </div>
            {!remainingTime && (
                <span style={{ color: 'red', fontSize: '14px', fontFamily: 'Poppins' }}>
                    OTP has been expired, please resend again.
                </span>
            )}
        </div>
    );
};

export default OtpForm;
