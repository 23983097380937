import React from 'react';
import logoImage from '../../../assets/images/logo.png';
import { ILogoWithDescriptionProps } from '.';

const LogoWithDescription: React.FC<ILogoWithDescriptionProps> = ({ description }) => {
    return (
        <div className="organizer-logo">
            <img src={logoImage} alt="Logo" />
            <h2 className='welcome-text'>{description}</h2>
        </div>
    );
};

export default LogoWithDescription;
