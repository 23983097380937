import { Spin } from 'antd';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Spinner: React.FC = () => (
    <Spin delay={3} indicator={antIcon} />
);

export default Spinner;


