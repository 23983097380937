import { createSlice } from '@reduxjs/toolkit';

interface AuthState {
  isAuthenticated: boolean;
  user: any;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthenticated: (state, action) => {
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },
    setUnAuthenticated: (state) => {
      state.isAuthenticated = false;
      localStorage.removeItem("authToken");
      localStorage.removeItem("user");
    }
  },
});

export const { setAuthenticated, setUnAuthenticated } = authSlice.actions;

export default authSlice.reducer;
