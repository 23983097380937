import axiosInstance from '../utils/axiosConfig';
import config from '../config/config';
import { setAuthenticated } from '../components/features/auth/authSlice';
import { ILoginProps } from '../pages/Login';
import { IRegisterProps } from '../pages/Register';
import formatError from '../utils/errorUtils';

const apiUrl = process.env.REACT_APP_ENV === 'development' ? config.development.apiUrl : config.production.apiUrl;

export const authService = {
    async register(payload: IRegisterProps) {
        try {
            const response = await axiosInstance.post(`${apiUrl}/register`, payload);
            return response.data;
        } catch (error) {
            throw formatError(error);
        }
    },

    async checkOrganizationExists(organizationName: string) {
        try {
            const response = await axiosInstance.post(`${apiUrl}/check-organization`, { organizationName });
            return response.data;
        } catch (error) {
            throw formatError(error);
        }
    },

    async checkEmailExists(email: string) {
        try {
            const response = await axiosInstance.post(`${apiUrl}/check-email`, { email });
            return response.data;
        } catch (error) {
            throw formatError(error);
        }
    },

    async verifyOtp(email: string, otp: string, dispatch: any) {
        try {
            const response = await axiosInstance.post(`${apiUrl}/verify-otp`, { email, otp });
            dispatch(setAuthenticated(response.data.data));
            return response.data;
        } catch (error) {
            throw formatError(error);
        }
    },

    async sendOtp(email: string) {
        try {
            const response = await axiosInstance.post(`${apiUrl}/send-otp`, { email });
            return response.data;
        } catch (error) {
            throw formatError(error);
        }
    },

    async login(payload: ILoginProps) {
        try {
            const response = await axiosInstance.post(`${apiUrl}/signin`, payload);
            return response.data;
        } catch (error) {
            throw formatError(error);
        }
    },
};