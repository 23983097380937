import React, { useState } from "react";
import Banner from "../../components/Common/Banner/Banner";
import { Link } from "react-router-dom";
import { IRegisterProps } from ".";
import { authService } from "../../services/authService";
import OtpForm from "../../components/Common/OtpForm/OtpForm";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { Button, Col, Form, Input, Row, Select } from "antd";
import LogoWithDescription from "../../components/Common/LogoWithDescription/LogoWithDescription";
import { useNotification } from "../../context/NotificationContext";
import "./RegisterPage.css";

type FormControlElement =
  | HTMLInputElement
  | HTMLTextAreaElement
  | HTMLSelectElement;

const organisationTypeOptions = [
  { label: 'Poll+Survey', value: 'POLLORSURVEY' },
  { label: 'Election', value: 'ELECTION' },
]

const RegisterPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isOrganizationExists, setIsOrganizationExists] = useState(false);
  const [verifyOtpLoading, setVerifyOtpLoading] = useState(false);
  const [isEmailExists, setIsEmailExists] = useState(false);
  const [form] = Form.useForm();
  const [isSendOtp, setIsSendOtp] = useState(false);
  const dispatch = useDispatch();
  const { openNotification } = useNotification();

  const handleSubmit = async (values: IRegisterProps) => {
    try {
      setLoading(true);
      const res = await authService.register({ ...values, middleName: "" });
      if (res.isSuccess) {
        setIsSendOtp(true);
        openNotification("success", res.message, "topRight");
      }
    } catch (error: any) {
      openNotification("error", error.message, "topRight");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitOtp = async (otp: any) => {
    try {
      setLoading(true);
      setVerifyOtpLoading(true);
      var res = await authService.verifyOtp(
        form.getFieldValue("email"),
        otp,
        dispatch
      );
      if (res.isSuccess) {
        openNotification("success", res?.data?.message, "topRight");
      }
    } catch (error: any) {
      openNotification("error", error.message, "topRight");
    } finally {
      setLoading(false);
      setVerifyOtpLoading(false);
    }
  };

  const handleResendOtp = async () => {
    try {
      setVerifyOtpLoading(true);
      var res = await authService.sendOtp(form.getFieldValue("email"));
      if (res.isSuccess) {
        openNotification("success", res.message, "topRight");
      }
    } catch (error: any) {
      openNotification("error", error.message, "topRight");
    } finally {
      setVerifyOtpLoading(false);
    }
  };

  const debouncedOrganizationInputChange = debounce(
    (event: React.ChangeEvent<FormControlElement>) => {
      const { value } = event.target;
      if (value) {
        checkOrganizationExists(value);
      }
    },
    300
  );

  const checkOrganizationExists = async (organizationName: string) => {
    try {
      await authService.checkOrganizationExists(organizationName);
      setIsOrganizationExists(false);
      form.setFields([
        { name: "organizationName", validated: true, errors: [] },
      ]);
    } catch (error: any) {
      setIsOrganizationExists(true);
      form.setFields([
        {
          name: "organizationName",
          validated: false,
          errors: [error.message],
        },
      ]);
    }
  };

  const isValidEmail = (email: string): boolean => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const debouncedEmailInputChange = debounce(
    (event: React.ChangeEvent<FormControlElement>) => {
      const { value } = event.target;
      if (value && isValidEmail(value)) {
        checkEmailExists(value);
      }
    },
    300
  );

  const checkEmailExists = async (email: string) => {
    try {
      await authService.checkEmailExists(email);
      setIsEmailExists(false);
      form.setFields([{ name: "email", validated: true, errors: [] }]);
    } catch (error: any) {
      setIsEmailExists(true);
      form.setFields([
        {
          name: "email",
          validated: false,
          errors: [error.message],
        },
      ]);
    }
  };

  return (
    <div>
      <Row style={{ display: "flex" }}>
        <Banner />
        <Col md={11} className="pollvote-background_color">
          <div className="setup-profile">
            <div className="setup-profile-inner">
              <LogoWithDescription
                description={
                  isSendOtp
                    ? `Please enter the OTP sent to ${form.getFieldValue(
                      "email"
                    )}`
                    : "Setup to continue"
                }
              />

              {isSendOtp ? (
                <OtpForm
                  buttonLabel="Verify"
                  loading={verifyOtpLoading}
                  onSubmit={handleSubmitOtp}
                  onResend={handleResendOtp}
                />
              ) : (
                <Form
                  form={form}
                  noValidate
                  name="register"
                  scrollToFirstError
                  onFinish={handleSubmit}
                >
                  <div className="did-floating-label-content">
                    <Form.Item
                      label=''
                      name="organiserType"
                    >
                      <Select
                        placeholder={'Select organiser type'}
                        filterOption={(input, option: any) => (option?.label ?? '').includes(input)}
                        options={organisationTypeOptions}
                      />
                    </Form.Item>
                    <Form.Item
                      name="organizationName"
                      rules={[
                        { required: true, message: "Party Name is Required!" },
                        {
                          validator: async (_, value) => {
                            if (isOrganizationExists) {
                              throw new Error("Organization already exists!");
                            }
                          },
                        },
                      ]}
                    >
                      <Input
                        className="did-floating-input"
                        placeholder="Organization Name"
                        onChange={(event) =>
                          debouncedOrganizationInputChange(event)
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      name="firstName"
                      rules={[
                        { required: true, message: "First Name is Required!" },
                      ]}
                    >
                      <>
                        <Input
                          className="did-floating-input"
                          placeholder="Your First Name"
                        />
                        {/* <label className="did-floating-label">Your First Name</label> */}
                      </>
                    </Form.Item>
                    <Form.Item name="middleName" rules={[{ required: false }]}>
                      <>
                        <Input
                          className="did-floating-input"
                          placeholder="Your Middle Name"
                        />
                        {/* <label className="did-floating-label">Your Middle Name (Optional)</label> */}
                      </>
                    </Form.Item>
                    <Form.Item
                      name="lastName"
                      rules={[
                        { required: true, message: "Last Name is Required!" },
                      ]}
                    >
                      <>
                        <Input
                          className="did-floating-input"
                          placeholder="Your Last Name"
                        />
                        {/* <label className="did-floating-label">Your Last Name</label> */}
                      </>
                    </Form.Item>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Email Address is Required!",
                        },
                        {
                          type: "email",
                          message: "Please enter a valid email address!",
                        },
                        {
                          validator: async (_, value) => {
                            if (isEmailExists) {
                              throw new Error("Email already exists!");
                            }
                          },
                        },
                      ]}
                    >
                      <>
                        <Input
                          className="did-floating-input"
                          placeholder="Your Email Address"
                          onChange={(event) => debouncedEmailInputChange(event)}
                        />
                        {/* <label className="did-floating-label">Your Email Address</label> */}
                      </>
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[
                        { required: true, message: 'Password is required!' },
                        { min: 8, message: 'Password must be at least 8 characters long.' },
                      ]}
                    >
                      <Input.Password
                        className="password-input"
                        placeholder="Password"
                        visibilityToggle
                      />
                    </Form.Item>
                    <div className="optverify">
                      {/* <Form.Item
                                                name="agreement"
                                                valuePropName="checked"
                                                rules={[
                                                    {
                                                        validator: (_, value) =>
                                                            value ? Promise.resolve() : Promise.reject(new Error('You must agree to the terms and conditions!')),
                                                    },
                                                ]}
                                            >
                                                <Checkbox className="checkboxes-continues">
                                                    I agree to terms and conditions
                                                </Checkbox>
                                            </Form.Item> */}
                    </div>
                  </div>

                  <Button
                    className="mb-3"
                    htmlType="submit"
                    style={{
                      background:
                        "transparent linear-gradient(180deg, #19C7AD 0%, #15A18C 100%) 0% 0% no-repeat padding-box",
                      borderColor: "#19C7AD",
                      color: "white",
                      width: "100%",
                      padding: "19px",
                      fontSize: "19px",
                      fontWeight: 300,
                      // marginTop: '10px',
                      borderRadius: "17px",
                      border: "none",
                      cursor: "pointer",
                      height: "66px",
                      transition:
                        "background-color 0.3s, color 0.3s, border-color 0.3s",
                    }}
                    loading={loading}
                  >
                    Continue
                  </Button>
                  <div className="mb-2 text-center ">
                    <Link
                      to="/login"
                      style={{
                        color: "#19C7AD",
                        fontSize: "16px",
                      }}
                    >
                      Already have an account?
                    </Link>
                  </div>
                </Form>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RegisterPage;
