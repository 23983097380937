import React, { createContext, useContext, ReactNode } from 'react';
import { notification } from 'antd';
import { NotificationInstance, NotificationPlacement } from 'antd/es/notification/interface';

type NotificationContextType = {
  openNotification: (type: 'success' | 'error', message: string, placement: NotificationPlacement, duration?: number | null) => void;
};

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

const useNotification = (): NotificationContextType => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

type NotificationProviderProps = {
  children: ReactNode;
};

const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const api = notification as NotificationInstance;

  const openNotification = (
    type: 'success' | 'error',
    message: string,
    placement: NotificationPlacement,
    duration: number | null = 2
  ) => {
    const notificationType = type === 'success' ? 'success' : 'error';
    api[notificationType]({
      message,
      description: ``,
      placement,
      duration
    });
  };

  const contextValue: NotificationContextType = {
    openNotification,
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationProvider, useNotification };
