const config = {
    development: {
        apiUrl: 'https://api.pollvote.org/api',
        // apiUrl: 'https://localhost:7199/api',
    },
    production: {
        apiUrl: 'https://api.pollvote.org/api',
        // apiUrl: 'https://localhost:7199/api',
    },
};

export default config;