import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import { NotificationProvider } from "../context/NotificationContext";
import "./App.css";
import HomePage from "../pages/Home/HomePage";
import RegisterPage from "../pages/Register/RegisterPage";
import LoginPage from "../pages/Login/LoginPage";
import ForgotPasswordPage from "../pages/ForgotPassword/ForgotPasswordPage";
import TermsAndConditionsPage from "../pages/TermsAndConditions/TermsAndConditionsPage";
import PrivacyAndPolicyPage from "../pages/PrivacyAndPolicy/PrivacyAndPolicyPage";

const DraftEventsPage = lazy(() => import("../pages/Events/Draft/DraftEventsPage"));
const ReadyEventsPage = lazy(() => import("../pages/Events/Ready/ReadyEventPage"));
const LiveEventsPage = lazy(() => import("../pages/Events/Live/LiveEventPage"));
const HistoryEventsPage = lazy(() => import("../pages/Events/History/HistoryEventsPage"));
const DashboardPage = lazy(() => import("../pages/Dashboard/DashboardPage"));
const PollPage = lazy(() => import("../pages/Poll/PollPage"));
const SurveyPage = lazy(() => import("../pages/Survey/SurveyPage"));
const ReferendumPage = lazy(() => import("../pages/Referendum/ReferendumPage"));
const RecallPage = lazy(() => import("../pages/Recall/RecallPage"));
const ElectionsPage = lazy(() => import("../pages/Elections/ElectionsPage"));
const ElectionPartiesPage = lazy(() => import("../pages/Elections/ElectionParties/ElectionPartiesPage"));
const ElectionCandidatesPage = lazy(() => import("../pages/Elections/ElectionCandidates/ElectionCandidatesPage"));

const App: React.FC = () => {
  return (
    <div className="App" style={{ backgroundColor: "#f2fefc" }}>
      <Router>
        <NotificationProvider>
          <Routes>
            <Route path="/" element={<PublicRoute />}>
              <Route index element={<HomePage />} />
              <Route path="register" element={<RegisterPage />} />
              <Route path="terms-and-conditions" element={<TermsAndConditionsPage />} />
              <Route path="privacy-policy" element={<PrivacyAndPolicyPage />} />
              <Route path="login" element={<LoginPage />} />
              <Route path="forgot-password" element={<ForgotPasswordPage />} />
            </Route>
            <Route path="/dashboard" element={<ProtectedRoute />}>
              <Route index element={<DashboardPage />} />
            </Route>
            <Route path="/poll" element={<ProtectedRoute />}>
              <Route index element={<PollPage />} />
              <Route path="edit/:id" element={<PollPage />} />
            </Route>
            <Route path="/survey" element={<ProtectedRoute />}>
              <Route index element={<SurveyPage />} />
              <Route path="edit/:id" element={<SurveyPage />} />
            </Route>
            <Route path="/referendum" element={<ProtectedRoute />}>
              <Route index element={<ReferendumPage />} />
              <Route path="edit/:id" element={<ReferendumPage />} />
            </Route>
            <Route path="/recall" element={<ProtectedRoute />}>
              <Route index element={<RecallPage />} />
              <Route path="edit/:id" element={<RecallPage />} />
            </Route>
            <Route path="/events/draft" element={<ProtectedRoute />}>
              <Route index element={<DraftEventsPage />} />
            </Route>
            <Route path="/events/live" element={<ProtectedRoute />}>
              <Route index element={<LiveEventsPage />} />
            </Route>
            <Route path="/events/ready" element={<ProtectedRoute />}>
              <Route index element={<ReadyEventsPage />} />
            </Route>
            <Route path="/events/history" element={<ProtectedRoute />}>
              <Route index element={<HistoryEventsPage />} />
            </Route>
            <Route path="/election" element={<ProtectedRoute />}>
              <Route index element={<ElectionsPage />} />
              <Route path="edit/:id" element={<ElectionsPage />} />
              <Route path="parties" element={<ElectionPartiesPage />} />
              <Route path="candidates" element={<ElectionCandidatesPage />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </NotificationProvider>
      </Router>
    </div>
  );
};

export default App;
