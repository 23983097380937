const formatError = (error: any) => {
  if (error.response && error.response.data) {
    return {
      message: error.response.data.message,
      status: error.response.status,
    };
  } else {
    return {
      message: "An error occurred!",
      status: error.response ? error.response.status : null,
    };
  }
};

export default formatError;
