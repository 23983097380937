import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import eventReducer from '../features/event/eventSlice';
import electionReducer from '../features/election/electionSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    event: eventReducer,
    election:electionReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
