import React from "react";
import banner from "../../../assets/images/setup-art.png";
import { Col } from "antd";

const Banner: React.FC = () => {
  return (
    <Col sm={13} className="pollvote-banner">
      <div className="banner">
        <div className="banner-inner">
          <img src={banner} alt="Background" className="background-image " />
        </div>
      </div>
    </Col>
  );
};

export default Banner;
