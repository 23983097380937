import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./app/store";
import { setAuthenticated } from "./features/auth/authSlice";

const PublicRoute: React.FC = () => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  return !isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/dashboard" />
  );
};

export default PublicRoute;
