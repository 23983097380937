// src/slices/electionSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Candidate {
    id: number;
    candidateName: string;
}
interface GovernmentIdType {
    label: string;
    value: string;
}

interface Position {
    id: number;
    positionName: string;
    candidates: Candidate[];
}

interface ElectionState {
    privateElectionPosition: any;
    governmentIdTypes: GovernmentIdType[];
    loading: boolean;
}

const initialState: ElectionState = {
    privateElectionPosition: [],
    governmentIdTypes: [],
    loading: false,
};

const electionSlice = createSlice({
    name: 'election',
    initialState,
    reducers: {
        setPositionName(state, action: PayloadAction<string>) {
            state.privateElectionPosition = action.payload;
            
        },
        setGovernmentIdTypes(state, action: PayloadAction<GovernmentIdType[]>) {
            state.governmentIdTypes = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
    },
});

export const {
    setPositionName,
    setGovernmentIdTypes,
    setLoading,
} = electionSlice.actions;

export default electionSlice.reducer;
