import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./components/app/store";
import "bootstrap/dist/css/bootstrap.css";
import { ConfigProvider } from "antd";
import { setAuthenticated, setUnAuthenticated } from "./components/features/auth/authSlice";
import { StyleProvider } from '@ant-design/cssinjs';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const userString = localStorage.getItem("user");

if (userString && Object.keys(userString)) {
  const user = JSON.parse(userString);
  store.dispatch(setAuthenticated({ user }));
} else {
  store.dispatch(setUnAuthenticated());
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#3cc4af",
            fontFamily: "poppins",
          },
          components: {
            Menu: {
              itemSelectedBg: "#3cc4af",
              itemSelectedColor: "#000000", 
              itemHoverBg: "#ccc", 
            },
          },
        }}
      >
        <StyleProvider hashPriority="high">
          <App />
        </StyleProvider>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
